import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import TrendingUp from "@material-ui/icons/TrendingUp";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import Info from "components/Typography/Info.js";
import Danger from "components/Typography/Danger.js";
import Success from "components/Typography/Success.js";
import Button from "components/CustomButtons/Button.js";

import blogsStyle from "assets/jss/material-kit-pro-react/views/sectionsSections/blogsStyle.js";

import cardBlog4 from "assets/img/examples/card-blog4.jpg";
import combo2 from "assets/img/images/75_068e1f3d_1_.jpg";


const useStyles = makeStyles(blogsStyle);

export default function SectionBlogs() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  });
  const classes = useStyles();
  return (
    <div className="cd-section">
      <div className={classes.textCenter}>
      <Button href="/" simple color="primary" size="lg">
        inREALTIME
      </Button>
    </div>
      <div className={classes.blog}>
        <div className={classes.container}>
          <GridContainer>
            <GridItem
              xs={12}
              sm={10}
              md={10}
              className={classes.mlAuto + " " + classes.mrAuto}
            >
              <h2 className={classes.title}>Hosting on dedicated Serveur</h2>
              <Card plain blog className={classes.card}>
                <GridContainer>
                  <GridItem xs={12} sm={5} md={5}>
                    <CardHeader image plain>
                      <a href="#" onClick={e => e.preventDefault()}>
                        <img src={combo2} alt="..." />
                      </a>
                      <div
                        className={classes.coloredShadow}
                        style={{
                          backgroundImage: `url(${combo2})`,
                          opacity: "1"
                        }}
                      />
                    </CardHeader>
                  </GridItem>
                  <GridItem xs={12} sm={7} md={7}>
                    <Info>
                      <h6 className={classes.cardCategory}>WITH OVH ENTERPRISE</h6>
                    </Info>
                    <h3 className={classes.cardTitle}>
                      High availability dedicated server hosting, daily backup, permanent monitoring, SSL certificate
                    </h3>
                    <p className={classes.description1}>
                      OVH Global Infrastructure<br />
                      70 Tbps global network capacity <br />
                      33 datacenters  implemented across 4 continents<br />
                      44 redundant PoPs worldwide<br />
                    </p>
                  </GridItem>
                </GridContainer>
              </Card>
              <Card plain blog className={classes.card}>
                <GridContainer>
                  <GridItem xs={12} sm={7} md={7}>
                    <Info>
                      <h6 className={classes.cardCategory}>
                        OUR SERVICE
                      </h6>
                    </Info>
                    <h3 className={classes.cardTitle}>
                      We take care of everything
                    </h3>
                    <p className={classes.description1}>
                      Your website is hosted on our servers in a Linux Apache Mysql Php environment<br />
                      The servers are monitored, we intervene in real time if necessary<br />
                      Updates are integrated
                    </p>
                  </GridItem>
                  <GridItem xs={12} sm={5} md={5}>
                    <CardHeader image plain>
                      <a href="#" onClick={e => e.preventDefault()}>
                        <img src={cardBlog4} alt="..." />
                      </a>
                      <div
                        className={classes.coloredShadow}
                        style={{
                          backgroundImage: `url(${cardBlog4})`,
                          opacity: "1"
                        }}
                      />
                    </CardHeader>
                  </GridItem>
                </GridContainer>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      </div>
    </div>
  );
}
