import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
// import Weekend from "@material-ui/icons/Weekend";
// import Home from "@material-ui/icons/Home";
// import Business from "@material-ui/icons/Business";
// import AccountBalance from "@material-ui/icons/AccountBalance";
// import Check from "@material-ui/icons/Check";
// import Close from "@material-ui/icons/Close";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import Button from "components/CustomButtons/Button.js";
import NavPills from "components/NavPills/NavPills.js";
// import Success from "components/Typography/Success.js";
// import Danger from "components/Typography/Danger.js";

import pricingStyle from "assets/jss/material-kit-pro-react/views/sectionsSections/pricingStyle.js";

// import bg11 from "assets/img/bg11.jpg";
// import city from "assets/img/examples/city.jpg";
import cardBlog3 from "assets/img/examples/card-blog3.jpg";

const useStyles = makeStyles(pricingStyle);

export default function SectionPricing() {
    React.useEffect(() => {
        window.scrollTo(0, 0);
        document.body.scrollTop = 0;
      });
  const classes = useStyles();
  return (
    <div className="cd-section">
        <div className={classes.container}>
          <GridContainer>
            <GridItem
              xs={12}
              sm={6}
              md={6}
              className={
                classes.mlAuto + " " + classes.mrAuto + " " + classes.textCenter
              }
            ><div className={classes.textCenter}>
            <Button href="/" simple color="primary" size="lg">
              inREALTIME
            </Button>
          </div>
              <h2 className={classes.title}>Pick the best plan for you</h2>
              <NavPills
                alignCenter
                color="primary"
                tabs={[
                  {
                    tabButton: "Monthly",
                    tabContent: "Support and Assistance"
                  },
                ]}
              />
              {/* <div className={classes.sectionSpace} /> */}
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={12} sm={4} md={4}>
              <Card pricing plain>
                <CardBody pricing plain>
                  <h6 className={classes.cardCategory}>FREE</h6>
                  <h1 className={classes.cardTitle}>
                    <small>$</small> 0
                  </h1>
                  <ul>
                    <li>
                      <b>1 Time</b> 
                    </li>
                    <li>
                      <b>to start your e-Shop</b>
                    </li>
                    <li>
                      <b>by call on WhatsAppp</b> 
                    </li>
                    <li>
                      <b>or by mail</b>
                    </li>
                  </ul>
                </CardBody>
                <CardFooter pricing className={classes.justifyContentCenter}>
                  <Button color="primary" round>
                    Get Started
                  </Button>
                </CardFooter>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={4} md={4}>
              <Card
                pricing
                raised
                background
                style={{ backgroundImage: `url(${cardBlog3})` }}
              >
                <CardBody pricing background>
                  <h6 className={classes.cardCategoryWhite}>PREMIUM</h6>
                  <h1 className={classes.cardTitleWhite}>
                    <small>$</small> 89 <small>/month</small>
                  </h1>
                  <ul>
                    <li>
                      <b>2 hours by month</b>
                    </li>
                    <li>
                      <b>to follow your e-Shop</b>
                    </li>
                    <li>
                    <b>by call on WhatsAppp</b> 
                    </li>
                    <li>
                      <b>or by mail</b>
                    </li>
                  </ul>
                </CardBody>
                <CardFooter pricing className={classes.justifyContentCenter}>
                  <Button color="white" round>
                    Get Started
                  </Button>
                </CardFooter>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={4} md={4}>
              <Card pricing plain>
                <CardBody pricing plain>
                  <h6 className={classes.cardCategory}>PLATINUM</h6>
                  <h1 className={classes.cardTitle}>
                    <small>$</small> 199 <small>/month</small>
                  </h1>
                  <ul>
                    <li>
                      <b>unlimited</b>
                    </li>
                    <li>
                    <b>to follow your e-Shop</b>
                    </li>
                    <li>
                    <b>by call on WhatsAppp</b> 
                    </li>
                    <li>
                      <b>or by mail</b>
                    </li>
                  </ul>
                </CardBody>
                <CardFooter pricing className={classes.justifyContentCenter}>
                  <Button color="primary" round>
                    Get Started
                  </Button>
                </CardFooter>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      </div>
      );
}
