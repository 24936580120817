import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
// import Weekend from "@material-ui/icons/Weekend";
// import Home from "@material-ui/icons/Home";
// import Business from "@material-ui/icons/Business";
// import AccountBalance from "@material-ui/icons/AccountBalance";
// import Check from "@material-ui/icons/Check";
// import Close from "@material-ui/icons/Close";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import Button from "components/CustomButtons/Button.js";
import NavPills from "components/NavPills/NavPills.js";
// import Success from "components/Typography/Success.js";
// import Danger from "components/Typography/Danger.js";

import pricingStyle from "assets/jss/material-kit-pro-react/views/sectionsSections/pricingStyle.js";

//import bg11 from "assets/img/bg11.jpg";
//import city from "assets/img/examples/city.jpg";
//import cardBlog3 from "assets/img/examples/card-blog3.jpg";
import city from "assets/img/PHOTO-BANGKOK.jpg";

const useStyles = makeStyles(pricingStyle);

export default function SectionPricing() {
    React.useEffect(() => {
        window.scrollTo(0, 0);
        document.body.scrollTop = 0;
      });
  const classes = useStyles();
  return (
    <div className="cd-section">
         <div
        className={classes.contacts + " " + classes.section}
        style={{ backgroundImage: `url(${city})` }}
      >
          
        {/* <div className={classes.pricing_ + " " + classes.sectionGray_}> */}
        <div className={classes.container}>
          <GridContainer>
            <GridItem
              xs={12}
              sm={4}
              md={4}
              className={classes.mlAuto + " " + classes.mrAuto}
            ><div className={classes.textCenter}>
      <Button href="/" simple color="white" size="lg">inREALTIME
      </Button>
    </div>
              <h2 className={classes.title+ " " + classes.textCenter}>
                Choose a plan for your Domain name
              </h2>
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem
              xs={12}
              sm={7}
              md={7}
              className={classes.mlAuto + " " + classes.mrAuto}
            >
              <NavPills
                alignCenter
                color="primary"
                tabs={[
                  {
                    tabButton: "FREE DOMAIN",
                    tabContent: (
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={12}>
                          <Card pricing raised>
                            <CardBody pricing>
                              <h6 className={classes.cardDescription}>
                              Get a free domain with inrealtime with SSL certificat
                              </h6>
                              <h1 className={classes.cardTitle}>
                                <small>$</small> 0
                              </h1>
                              <ul>
                                <li>
                                  <b>the name of your enterprise</b>
                                </li>
                                <li>
                                  <b>inrealtime</b>
                                </li>
                                <li>
                                  <b>.fr</b>
                                </li>
                                <li>
                                  <b>yourEnterprise.inrealtime.fr</b>
                                </li>
                              </ul>
                            </CardBody>
                            <CardFooter
                              pricing
                              className={classes.justifyContentCenter}
                            >
                              <Button color="primary" round>
                                FREE DOMAIN REQUEST
                              </Button>
                            </CardFooter>
                          </Card>
                        </GridItem>
                      
                      </GridContainer>
                    )
                  },
                  {
                    tabButton: "BUY A DOMAIN",
                    tabContent: (
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={12}>
                          <Card pricing raised>
                            <CardBody pricing>
                              <h6 className={classes.cardDescription}>
                              Buy a domain on Ovh.com with SSL certificat</h6>
                              <h1 className={classes.cardTitle}>
                                <small>$</small> 50
                              </h1>
                              <ul>
                                <li>
                                  <b>for one year renewable</b> 
                                </li>
                                <li>
                                  <b>the name of your enterprise</b>
                                </li>
                                <li>
                                  <b>.com</b>
                                </li>
                                <li>
                                  <b>www.yourEnterprise.com</b>
                                </li>
                              </ul>
                            </CardBody>
                            <CardFooter
                              pricing
                              className={classes.justifyContentCenter}
                            >
                              <Button color="primary" round>
                                BUY NOW on OVH
                              </Button>
                            </CardFooter>
                          </Card>
                        </GridItem>
                        
                      </GridContainer>
                    )
                  }
                ]}
              />
            </GridItem>
          </GridContainer>
        </div>
      </div>
    </div>
  );
}
