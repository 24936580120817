import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// core components

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import Footer from "components/Footer/Footer.js";
// import List from "@material-ui/core/List";
// import ListItem from "@material-ui/core/ListItem";

import { makeStyles } from "@material-ui/core/styles";

import colorDescriptionStyle from "assets/jss/material-kit-pro-react/views/presentationSections/colorDescriptionStyle.js";

import imageScanneQRCODEinREALTIME from "assets/img/imageScanneinRealtime.png";


const useStyles = makeStyles(colorDescriptionStyle);

export default function SectionFooter() {
  const classes = useStyles();
  return (
    <div className={" "}>
      {/* <div className={classes.container}> */}
      <Footer
        theme="dark"
        content={
          <div>
            <div className={classes.left}>
              {/* <List className={classes.list}>
                <ListItem className={classes.inlineBlock}>
                  <a
                    href="http://blog.creative-tim.com/?ref=mkpr-e-ecommerce"
                    target="_blank"
                    className={classes.block}
                  >
                    Blog
                  </a>
                </ListItem>
                <ListItem className={classes.inlineBlock}>
                  <a
                    href="https://www.creative-tim.com/presentation?ref=mkpr-e-ecommerce"
                    target="_blank"
                    className={classes.block}
                  >
                    Presentation
                  </a>
                </ListItem>
                <ListItem className={classes.inlineBlock}>
                  <a
                    href="#pablito"
                    onClick={e => e.preventDefault()}
                    className={classes.block}
                  >
                    Discover
                  </a>
                </ListItem>
                <ListItem className={classes.inlineBlock}>
                  <a
                    href="#pablito"
                    onClick={e => e.preventDefault()}
                    className={classes.block}
                  >
                    Payment
                  </a>
                </ListItem>
                <ListItem className={classes.inlineBlock}>
                  <a
                    href="https://www.creative-tim.com/contact-us?ref=mkpr-e-ecommerce"
                    target="_blank"
                    className={classes.block}
                  >
                    Contact us
                  </a>
                </ListItem>
              </List> */}
            </div>
            <div className={classes.right}>
              Copyright inREALTIME &copy; {1900 + new Date().getYear()}{" "}
              {/* <a
                href="https://www.creative-tim.com?ref=mkpr-e-ecommerce"
                target="_blank"
                className={classes.aClasses}
              > */}
                
              {/* </a>{" "} */}
              Pictures et Photos. Made with inREALTIME.
            </div>
          </div>
        }
      >
        <GridContainer>
          <GridItem xs={12} sm={4} md={4}>
            <h5>Payment</h5>
            <p><i className="far fa-credit-card"></i> Credit Card</p>
                             <p><i className="fab fa-cc-mastercard"></i> Mastercard</p>
                             <p><i className="fab fa-cc-visa"></i> Visa</p>
                             <p><i className="fab fa-paypal"></i> Paypal</p>
                             <p><i className="fas fa-university"></i> Transfer Bank</p>
          </GridItem>
          <GridItem xs={12} sm={4} md={4}>
            <h5>Legal</h5>
            <div className={classes.socialFeed}>
              <div>
              <a href="https://inrealtime.fr/CGV_INREALTIME.pdf"
              target="_blank"
                    // onClick={e => e.preventDefault()}
                    className={classes.block}
                  >
                    Terms of Sale
                  </a>
                </div>
                <br />
              <div>
              <a href="https://inrealtime.fr/MENTIONS_LEGALES_INREALTIME.pdf"
              target="_blank"
                    // onClick={e => e.preventDefault()}
                    className={classes.block}
                  >
                    Legal notice
                  </a>                
              </div>
            </div>
          </GridItem>
          <GridItem xs={12} sm={4} md={4}>
            <h5>Scan</h5>
            <div className={classes.galleryFeed}>
              <img
                src={imageScanneQRCODEinREALTIME}
                className={classNames(
                  classes.img,
                  classes.imgRaised,
                  classes.imgRounded
                )}
                alt="inREALTIME"
              />
              {/* <img
                src={face2}
                className={classNames(
                  classes.img,
                  classes.imgRaised,
                  classes.imgRounded
                )}
                alt="..."
              /> */}
              {/* <img
                src={face3}
                className={classNames(
                  classes.img,
                  classes.imgRaised,
                  classes.imgRounded
                )}
                alt="..."
              /> */}
              {/* <img
                src={face4}
                className={classNames(
                  classes.img,
                  classes.imgRaised,
                  classes.imgRounded
                )}
                alt="..."
              /> */}
              {/* <img
                src={face5}
                className={classNames(
                  classes.img,
                  classes.imgRaised,
                  classes.imgRounded
                )}
                alt="..."
              /> */}
              {/* <img
                src={face6}
                className={classNames(
                  classes.img,
                  classes.imgRaised,
                  classes.imgRounded
                )}
                alt="..."
              /> */}
              {/* <img
                src={face7}
                className={classNames(
                  classes.img,
                  classes.imgRaised,
                  classes.imgRounded
                )}
                alt="..."
              /> */}
              {/* <img
                src={face8}
                className={classNames(
                  classes.img,
                  classes.imgRaised,
                  classes.imgRounded
                )}
                alt="..."
              /> */}
            </div>
          </GridItem>
        </GridContainer>
      </Footer>
      {/* </div> */}
    </div>
  );
}
