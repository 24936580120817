import React from "react";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import componentsStyle from "assets/jss/material-kit-pro-react/views/presentationSections/componentsStyle.js";

import videoToPlay from "assets/videos/inRealtimeMovie.mp4";
import sohoPicture from "assets/img/propositionPageSmall.jpg";
//import video from "assets/videos/inREALTIME_promotion_FR.mp4";
import inrealtimeImage from "assets/img/inREALTIME.jpg";

import ShoppingCart from "@material-ui/icons/ShoppingCart";
import Button from "components/CustomButtons/Button.js";

// import macbookImage from "assets/img/assets-for-demo/presentationViewSectionComponent/laptop-basics.png";
// import shoppingCartImage from "assets/img/assets-for-demo/presentationViewSectionComponent/table.jpg";
//import shareButtonImage from "assets/img/assets-for-demo/presentationViewSectionComponent/share-btn.jpg";
// import cardImage from "assets/img/assets-for-demo/presentationViewSectionComponent/coloured-card-with-btn.jpg";
// import twitterImage from "assets/img/assets-for-demo/presentationViewSectionComponent/coloured-card.jpg";
// import iconsImage from "assets/img/assets-for-demo/presentationViewSectionComponent/social-row.jpg";
// import repostImage from "assets/img/assets-for-demo/presentationViewSectionComponent/pin-btn.jpg";

const useStyles = makeStyles(componentsStyle);


export default function SectionComponents() {
  const classes = useStyles();
  return (
    // <div>
    <div className={classes.container}>
      <GridContainer>
      <GridItem xs={12} sm={6} md={6}>
        <Button className={classes.shareButton}
            href="https://www.inrealtime.fr/MENTIONS_LEGALES_INREALTIME.pdf"
            color={"primary"}
            target="_blank"
            round
          >
            Legal
          </Button>{" "}
          <Button className={classes.shareButton2}
            href="https://www.inrealtime.fr/CGV_INREALTIME.pdf"
            color={"info"}
            target="_blank"
            round
          >
            TOS&nbsp;<ShoppingCart className={classes.icons} />
          </Button>{" "}
          <br />
          <h2 className={classes.title}>Why should you choose inREALTIME for your e-Shop...</h2>
          <h4 className={''}>
            The e-Shop template can be used instantly, create your products catalog.
            <br /><br />
            Easily integrate pictures and texts to personalize your homepage.
            <br /><br />
            Stay in touch with your customers using a contact form.
            <br /><br />
            And a page to log in to administer your content.
          </h4>
          <h5 className={''}>
            We offer solutions for your international online payments with Paypal or Wise.
          </h5>
        </GridItem>
        <GridItem xs={12} sm={5} md={5} className={classes.mlAuto}>
          <div className={classes.imageContainer}>
            <img
              src={sohoPicture}
              alt="inREALTIME"
              className={classes.componentsMacbook}
            />
          </div>
        </GridItem>
      </GridContainer>
      <GridContainer>
      <GridItem xs={12} sm={6} md={6}>
      <Button
            color="danger"
            size="md"
            rel=""
          >
            {/* <i className="fas fa-ticket-alt" /> */}
            Order Now
          </Button>
          <img
            src={inrealtimeImage}
            alt="inREALTIME"
            className={classes.cardImage}
          />
          </GridItem>
          </GridContainer>
    </div>

  );
}
