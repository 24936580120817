/*eslint-disable*/
import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
// core components
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import { Link } from "react-router-dom";
// import Footer from "components/Footer/Footer.js";
// sections for this page

import ToggleVisibilityNavigation from "components/ToggleVisibility/ToggleVisibilityNavigation.js";
import SectionExamples from "views/PresentationPage/Sections/SectionExamples.js";
import SectionComponents from "views/PresentationPage/Sections/SectionComponents.js";
import SectionContent from "views/PresentationPage/Sections/SectionContent.js";


import SectionDescription from "views/PresentationPage/Sections/SectionDescription.js";
// import SectionVideo from "views/PresentationPage/Sections/SectionVideo.js";
// import SectionCards from "views/PresentationPage/Sections/SectionCards.js";
import SectionSections from "views/PresentationPage/Sections/SectionSections.js";
import SectionProducts from "views/PresentationPage/Sections/SectionProducts.js";

import SectionFreeDemo from "views/PresentationPage/Sections/SectionFreeDemo.js";
import SectionOverview from "views/PresentationPage/Sections/SectionOverview.js";
import SectionPricing from "views/PresentationPage/Sections/SectionPricing.js";

import SectionFooter from "views/PresentationPage/Sections/SectionFooter.js";


import presentationStyle from "assets/jss/material-kit-pro-react/views/presentationStyle.js";

import videoBackground from "assets/videos/inREALTIME_vs.mp4";

import "./style.css";
// import imageScanneQRCODEinREALTIME from "assets/img/81_aad4f689_1_.png";


const useStyles = makeStyles(presentationStyle);


export default function PresentationPage() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  });
  const classes = useStyles();
  return (
    <div>
      {/* <Header
        brand="inREALTIME"
        links={<HeaderLinks dropdownHoverColor="info" />}
        fixed
        color="transparent"
        changeColorOnScroll={{
          height: 700,
          color: "white"
        }}
      /> */}
      <div className="main">
        <div className="overlay"></div>
        <video className="videoCover" src={videoBackground} autoPlay muted loop playsInline />
        <div className="content">
          <span className={classes.shadowTitle}>
            <span className={classes.proBadge}>inREALTIME</span>
          </span>
          <br />
          <h3 className={classes.title}>
            Develop your Business
          </h3>
          <h3 className={classes.title}>
            Connect with your customers
            </h3>
          <ToggleVisibilityNavigation />
        </div>
      </div>
      <div className={classNames(classes.main, classes.mainRaised_)}>
        {/* <SectionDescription /> */}
        <SectionExamples />
        {/* <SectionProducts /> */}


        {/* <SectionVideo /> */}
        <SectionComponents />
        {/* <SectionCards /> */}
        <SectionContent />
        {/* <SectionSections /> */}
        {/* <SectionExamples /> */}
        {/* <SectionFreeDemo /> */}
        {/* <SectionOverview /> */}
      </div>
      <SectionPricing />
      <SectionFooter />


      {/* <Footer
        theme="dark"
        content={
          <div>
            <div className={classes.left}>
              <a
                href="https://www.creative-tim.com/product/material-kit-pro-react?ref=mkpr-presentation"
                target="_blank"
                className={classes.footerBrand}
              >
                Material Kit PRO React
              </a>
            </div>
            <div className={classes.pullCenter}>
              <List className={classes.list}>
                <ListItem className={classes.inlineBlock}>
                  <a
                    href="https://www.creative-tim.com/?ref=mkpr-presentation"
                    target="_blank"
                    className={classes.block}
                  >
                    Creative Tim
                  </a>
                </ListItem>
                <ListItem className={classes.inlineBlock}>
                  <a
                    href="https://www.creative-tim.com/presentation?ref=mkpr-presentation"
                    target="_blank"
                    className={classes.block}
                  >
                    About us
                  </a>
                </ListItem>
                <ListItem className={classes.inlineBlock}>
                  <a href="//blog.creative-tim.com/" className={classes.block}>
                    Blog
                  </a>
                </ListItem>
                <ListItem className={classes.inlineBlock}>
                  <a
                    href="https://www.creative-tim.com/license?ref=mkpr-presentation"
                    target="_blank"
                    className={classes.block}
                  >
                    Licenses
                  </a>
                </ListItem>
              </List>
            </div>
            <div className={classes.rightLinks}>
              <ul>
                <li>
                  <Button
                    href="https://twitter.com/CreativeTim?ref=creativetim"
                    target="_blank"
                    color="twitter"
                    justIcon
                    simple
                  >
                    <i className="fab fa-twitter" />
                  </Button>
                </li>
                <li>
                  <Button
                    href="https://dribbble.com/creativetim?ref=creativetim"
                    target="_blank"
                    color="dribbble"
                    justIcon
                    simple
                  >
                    <i className="fab fa-dribbble" />
                  </Button>
                </li>
                <li>
                  <Button
                    href="https://instagram.com/CreativeTimOfficial?ref=creativetim"
                    target="_blank"
                    color="instagram"
                    justIcon
                    simple
                  >
                    <i className="fab fa-instagram" />
                  </Button>
                </li>
              </ul>
            </div>
          </div>
        }
      /> */}

    </div>
  );
}
