import React from "react";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// library used for cool animations
import ScrollAnimation from "react-animate-on-scroll";
import "animate.css/animate.min.css";

import contentStyle from "assets/jss/material-kit-pro-react/views/presentationSections/contentStyle.js";
// images

import imageQRCODEinREALTIME from "assets/img/QRCODEinREALTIME.jpg";
import imageDomaineSearch from "assets/img/domaineSearch.jpg";
import imageReferencement from "assets/img/referencement.jpg";


// import presentationiPad from "assets/img/assets-for-demo/presentationViewSectionComponent/presentation-ipad.jpg";
// import presentationiPadComments from "assets/img/assets-for-demo/presentationViewSectionComponent/ipad-comments.jpg";
// import presentationiPadTable from "assets/img/assets-for-demo/presentationViewSectionComponent/ipad-table.jpg";
import whatsappImage from "assets/img/Whatsapp.png";

const useStyles = makeStyles(contentStyle);

export default function SectionContent() {
  const classes = useStyles();
  return (
    <div className={classes.section}>
      <div className={classes.container}>
        <GridContainer justify="center">
          <GridItem md={5}>
            <div className={classes.sectionDescription}>
              <h3 className={classes.title}>Included in the e-Shop template</h3>
              <h4 className={" "}>
              High availability dedicated server hosting,
              daily backup,
              permanent monitoring,
              SSL certificate.
              </h4>
              <h5 className={" "}>We offer free assistance for getting started with your e-Shop website.
              </h5>
              <h3 className={classes.title}>
              With the e-Shop Template:</h3>
              <h5>
              You can get your personalized domain name, we will assist you in this process*.</h5>
              <span className={classes.description}>
                * Check the cost on www.ovh.com.
              </span>
              <h4>
              We offer natural SEO for free or paid with Adwords.</h4>
              <h5>For getting information contact us on</h5>
            </div>
            <div className={classes.imageContainer}>
              <img
                src={whatsappImage}
                alt="iPad comments"
              />
            
            <span className={classes.title}>&nbsp;&nbsp; +33 6 34 45 93 47</span></div>
          </GridItem>
          <GridItem md={7} className={classes.mlAuto}>
            {/* <p>..</p><p>..</p><p>..</p> */}
            <div className={classes.imageContainer}>
              <div className={classes.animeAreaImg}>
                <ScrollAnimation animateIn="animate__fadeInUp">
                  <img
                    src={imageReferencement}
                    alt="inREALTIME"
                    className={classes.areaImg}
                  />
                </ScrollAnimation>
              </div>
              <div className={classes.animeInfoImg}>
                <ScrollAnimation animateIn="animate__fadeInUp">
                  <img
                    src={imageDomaineSearch}
                    alt="inREALTIME"
                    className={classes.infoImg}
                  />
                </ScrollAnimation>
              </div>
              <img
                className={classes.ipadImg}
                src={imageQRCODEinREALTIME}
                alt="inREALTIME"
              />
            </div>
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}
