import React from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Icon from "@material-ui/core/Icon";
// @material-ui/icons
import Favorite from "@material-ui/icons/Favorite";
import Share from "@material-ui/icons/Share";
import ChatBubble from "@material-ui/icons/ChatBubble";
import Schedule from "@material-ui/icons/Schedule";
import TrendingUp from "@material-ui/icons/TrendingUp";
import Subject from "@material-ui/icons/Subject";
import WatchLater from "@material-ui/icons/WatchLater";
import People from "@material-ui/icons/People";
import Business from "@material-ui/icons/Business";
import Check from "@material-ui/icons/Check";
import Close from "@material-ui/icons/Close";
import Delete from "@material-ui/icons/Delete";
import Bookmark from "@material-ui/icons/Bookmark";
import Refresh from "@material-ui/icons/Refresh";
import Receipt from "@material-ui/icons/Receipt";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import CardAvatar from "components/Card/CardAvatar.js";
import Info from "components/Typography/Info.js";
import Danger from "components/Typography/Danger.js";
import Success from "components/Typography/Success.js";
import Warning from "components/Typography/Warning.js";
import Rose from "components/Typography/Rose.js";
import Button from "components/CustomButtons/Button.js";

import styles from "assets/jss/material-kit-pro-react/views/componentsSections/sectionCards.js";

import cardBlog1 from "assets/img/examples/card-blog1.jpg";
import cardBlog2 from "assets/img/examples/card-blog2.jpg";
import cardBlog3 from "assets/img/examples/card-blog3.jpg";
import cardBlog5 from "assets/img/examples/card-blog5.jpg";
import cardBlog6 from "assets/img/examples/card-blog6.jpg";
import cardProfile1 from "assets/img/examples/card-profile1.jpg";
import cardProfile4 from "assets/img/examples/card-profile4.jpg";
import blog1 from "assets/img/examples/blog1.jpg";
import blog5 from "assets/img/examples/blog5.jpg";
import blog6 from "assets/img/examples/blog6.jpg";
import blog8 from "assets/img/examples/blog8.jpg";
import avatar from "assets/img/faces/avatar.jpg";
import christian from "assets/img/faces/christian.jpg";
import marc from "assets/img/faces/marc.jpg";
import office1 from "assets/img/examples/office1.jpg";


import WEBSITE_MDC from "assets/img/WEBSITE_MDC.jpg";
import WEBSITE_SWEETYFORM from "assets/img/WEBSITE_SWEETYFORM.jpg";
import WEBSITE_EFLEX from "assets/img/WEBSITE_EFLEX.jpg";
import WEBSITE_MELISSA from "assets/img/WEBSITE_MELISSA.jpg";
import WEBSITE_JLE from "assets/img/WEBSITE_JLE.jpg";
import WEBSITE_LDFDD from "assets/img/WEBSITE_LDFDD.jpg";

import WEBSITE_FLEX from "assets/img/WEBSITE_FLEX.jpg";
import WEBSITE_ARTWEB from "assets/img/WEBSITE_ARTWEB.jpg";
import WEBSITE_INREALTIME from "assets/img/WEBSITE_INREALTIME.jpg";
import WEBSITE_MHOO from "assets/img/WEBSITE_MHOO.jpg";


const useStyles = makeStyles(styles);

export default function SectionCards() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  });
  const classes = useStyles();
  return (
    <div className="cd-section">
      <div className={classes.sectionGray}>
        {/* DYNAMIC COLORED SHADOWS START */}
        <div className={classes.container}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <div className={classes.textCenter}>
                <Button href="/" simple color="primary" size="lg">
                  inREALTIME
                </Button>
              </div>
              <div className={classes.title}>
              <h3 className={classes.title}>View our e-shop websites realizations and business websites</h3>
              </div>
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={12} sm={6} md={4}>
              <Card blog>
                <CardHeader image>
                  <a href="https://www.mydenimcollection.com"
                    target="_blank">
                    <img src={WEBSITE_MDC} alt="..." />
                  </a>
                  <div
                    className={classes.coloredShadow}
                    style={{
                      backgroundImage: `url(${WEBSITE_MDC})`,
                      opacity: "1"
                    }}
                  />
                </CardHeader>
                <CardBody>
                  <Rose>
                    <h6 className={classes.cardCategory}>MY DENIM COLLECTION</h6>
                  </Rose>
                  <h4 className={classes.cardTitle}>
                    <a href="#" onClick={e => e.preventDefault()}>
                    E-Shop website selling unique bag models<br /><br /></a>
                  </h4>
                </CardBody>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={6} md={4}>
              <Card blog>
                <CardHeader image>
                <a href="https://www.sweetyform.com"
                    target="_blank">
                  <img src={WEBSITE_SWEETYFORM} alt="..." />
                  </a>
                  <div
                    className={classes.coloredShadow}
                    style={{
                      backgroundImage: `url(${WEBSITE_SWEETYFORM})`,
                      opacity: "1"
                    }}
                  />
                </CardHeader>
                <CardBody>
                  <Warning>
                    <h6 className={classes.cardCategory}>SWEETYFORM</h6>
                  </Warning>
                  <h4 className={classes.cardTitle}>
                    <a href="#" onClick={e => e.preventDefault()}>
                    Food rebalancing, eating well to be healthier and lose weight, e-Shop Website</a>
                  </h4>
                </CardBody>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={6} md={4}>
              <Card blog>
                <CardHeader image>
                <a href="https://eflex.lemajordome.fr"
                    target="_blank">
                  <img src={WEBSITE_EFLEX} alt="..." />
                  </a>
                  <div
                    className={classes.coloredShadow}
                    style={{
                      backgroundImage: `url(${WEBSITE_EFLEX})`,
                      opacity: "1"
                    }}
                  />
                </CardHeader>
                <CardBody>
                  <Info>
                    <h6 className={classes.cardCategory}>EFLEX MLV</h6>
                  </Info>
                  <h4 className={classes.cardTitle}>
                    <a href="#" onClick={e => e.preventDefault()}>
                    Buy recycled or new furniture, MarketPlace e-Shop Website<br /><br />
                    </a>
                  </h4>
                </CardBody>
              </Card>
            </GridItem>
          
            <GridItem xs={12} sm={6} md={4}>
              <Card blog>
                <CardHeader image>
                <a href="https://villamelissa.inrealtime.fr"
                    target="_blank">
                  <img src={WEBSITE_MELISSA} alt="..." />
                  </a>
                  <div
                    className={classes.coloredShadow}
                    style={{
                      backgroundImage: `url(${WEBSITE_MELISSA})`,
                      opacity: "1"
                    }}
                  />
                </CardHeader>
                <CardBody>
                  <Warning>
                    <h6 className={classes.cardCategory}>VILLA MELISSA</h6>
                  </Warning>
                  <h4 className={classes.cardTitle}>
                    <a href="#" onClick={e => e.preventDefault()}>
                    Enjoy the calm azure waters, lush green landscape and swaying coconut palms, villa for Rent</a>
                  </h4>
                </CardBody>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={6} md={4}>
              <Card blog>
                <CardHeader image>
                <a href="https://jledistribution.inrealtime.fr"
                    target="_blank">
                  <img src={WEBSITE_JLE} alt="..." />
                  </a>
                  <div
                    className={classes.coloredShadow}
                    style={{
                      backgroundImage: `url(${WEBSITE_JLE})`,
                      opacity: "1"
                    }}
                  />
                </CardHeader>
                <CardBody>
                  <Rose>
                    <h6 className={classes.cardCategory}>JLE DISTRIBUTION</h6>
                  </Rose>
                  <h4 className={classes.cardTitle}>
                    <a href="#" onClick={e => e.preventDefault()}>
                    For guarantee the safety of your personal and professional vehicles, e-Shop Website</a>
                  </h4>
                </CardBody>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={6} md={4}>
              <Card blog>
                <CardHeader image>
                <a href="https://www.ledernierfilsdesdieux.com"
                    target="_blank">
                  <img src={WEBSITE_LDFDD} alt="..." />
                  </a>
                  <div
                    className={classes.coloredShadow}
                    style={{
                      backgroundImage: `url(${WEBSITE_LDFDD})`,
                      opacity: "1"
                    }}
                  />
                </CardHeader>
                <CardBody>
                  <Info>
                    <h6 className={classes.cardCategory}>THE LAST SON OF THE GODS</h6>
                  </Info>
                  <h4 className={classes.cardTitle}>
                    <a href="#" onClick={e => e.preventDefault()}>
                    Order the trilogy now in Kindle or paper format on Amazon, e-Shop Website</a>
                  </h4>
                </CardBody>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={6} md={4}>
              <Card blog>
                <CardHeader image>
                <a href="https://www.artwebprod.com"
                    target="_blank">
                  <img src={WEBSITE_ARTWEB} alt="..." />
                  </a>
                  <div
                    className={classes.coloredShadow}
                    style={{
                      backgroundImage: `url(${WEBSITE_ARTWEB})`,
                      opacity: "1"
                    }}
                  />
                </CardHeader>
                <CardBody>
                  <Info>
                    <h6 className={classes.cardCategory}>ARTWEBPROD</h6>
                  </Info>
                  <h4 className={classes.cardTitle}>
                    <a href="#" onClick={e => e.preventDefault()}>
                    French Web Agency since 2007, Creation of Websites and apps, conception and design of all projects</a>
                  </h4>
                </CardBody>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={6} md={4}>
              <Card blog>
                <CardHeader image>
                <a href="https://flex.lemajordome.fr"
                    target="_blank">
                  <img src={WEBSITE_FLEX} alt="..." />
                  </a>
                  <div
                    className={classes.coloredShadow}
                    style={{
                      backgroundImage: `url(${WEBSITE_FLEX})`,
                      opacity: "1"
                    }}
                  />
                </CardHeader>
                <CardBody>
                  <Warning>
                    <h6 className={classes.cardCategory}>FLEX BY LE MAJORDOME</h6>
                  </Warning>
                  <h4 className={classes.cardTitle}>
                    <a href="#" onClick={e => e.preventDefault()}>
                    Inventory, storage,
management of furniture projects, developed in partnership with Le Majordome des Lieux de Vie since 2003                    </a>
                  </h4>
                </CardBody>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={6} md={4}>
              <Card blog>
                <CardHeader image>
                <a href="https://www.myhouseoo.com"
                    target="_blank">
                  <img src={WEBSITE_MHOO} alt="..." />
                  </a>
                  <div
                    className={classes.coloredShadow}
                    style={{
                      backgroundImage: `url(${WEBSITE_MHOO})`,
                      opacity: "1"
                    }}
                  />
                </CardHeader>
                <CardBody>
                  <Info>
                    <h6 className={classes.cardCategory}>MY HOUSE OO</h6>
                  </Info>
                  <h4 className={classes.cardTitle}>
                    <a href="#" onClick={e => e.preventDefault()}>
                   WebSite Template for real estate agency,  search and find your property, sell and rent with trust!<br /><br /></a>
                  </h4>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      </div>
    </div>
  );
}
