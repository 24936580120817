/* eslint-disable */
import React from "react";
// @material-ui/core components
import classNames from "classnames";

import { makeStyles } from "@material-ui/core/styles";
import Icon from "@material-ui/core/Icon";
// @material-ui/icons
import Build from "@material-ui/icons/Build";
import Subject from "@material-ui/icons/Subject";
import FormatPaint from "@material-ui/icons/FormatPaint";
import Code from "@material-ui/icons/Code";
import Dashboard from "@material-ui/icons/Dashboard";
import Timeline from "@material-ui/icons/Timeline";
import Group from "@material-ui/icons/Group";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import NavPills from "components/NavPills/NavPills.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import Button from "components/CustomButtons/Button.js";
import Muted from "components/Typography/Muted.js";
import InfoArea from "components/InfoArea/InfoArea.js";
import Badge from "components/Badge/Badge.js";

import projectsStyle from "assets/jss/material-kit-pro-react/views/sectionsSections/projectsStyle.js";

import office2 from "assets/img/examples/office2.jpg";
import cardBlog3 from "assets/img/examples/card-blog3.jpg";
import cardProject1 from "assets/img/examples/card-project1.jpg";
import cardProject2 from "assets/img/examples/card-project2.jpg";
import cardProject3 from "assets/img/examples/card-project3.jpg";
import cardProject4 from "assets/img/examples/card-project4.jpg";
import cardProject5 from "assets/img/examples/card-project5.jpg";
import cardProject6 from "assets/img/examples/card-project6.jpg";

const useStyles = makeStyles(projectsStyle);

export default function SectionProducts() {
  const classes = useStyles();
  return (
    <div className={classNames(classes.section, classes.sectionDark)}>

    {/* <div
      className={
        classes.sectionDark
      }
    > */}
      <div className={classes.container}>
        <GridContainer>
          <GridItem
            xs={12}
            sm={8}
            md={8}
            className={
              classes.mlAuto + " " + classes.mrAuto + " " + classes.textCenter
            }
          >
            <Muted>
              <h6>OUR WORK</h6>
            </Muted>
            <h2 className={classes.title}>
              Some of Our Awesome Products - 3
            </h2>
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} sm={5} md={5} className={classes.mlAuto}>
            <Card plain className={classes.card2}>
              <a href="#pablo" onClick={e => e.preventDefault()}>
                <CardHeader plain image>
                  <img src={cardProject1} alt="..." />
                </CardHeader>
              </a>
              <CardBody>
                <h6 className={classes.cardCategory}>WEB DESIGN</h6>
                <a href="#pablo" onClick={e => e.preventDefault()}>
                  <h4 className={classes.cardTitle}>
                    Famous Website Redesign
                  </h4>
                </a>
                <p className={classes.cardDescription}>
                  Don{"'"}t be scared of the truth because we need to restart
                  the human foundation in truth.
                </p>
              </CardBody>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={5} md={5} className={classes.mrAuto}>
            <Card plain className={classes.card2}>
              <a href="#pablo" onClick={e => e.preventDefault()}>
                <CardHeader plain image>
                  <img src={cardProject2} alt="..." />
                </CardHeader>
              </a>
              <CardBody>
                <h6 className={classes.cardCategory}>PRODUCTIVITY TOOLS</h6>
                <a href="#pablo" onClick={e => e.preventDefault()}>
                  <h4 className={classes.cardTitle}>
                    Beautiful Desktop for Designers
                  </h4>
                </a>
                <p className={classes.cardDescription}>
                  Don{"'"}t be scared of the truth because we need to restart
                  the human foundation in truth.
                </p>
              </CardBody>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={5} md={5} className={classes.mlAuto}>
            <Card plain className={classes.card2}>
              <a href="#pablo" onClick={e => e.preventDefault()}>
                <CardHeader plain image>
                  <img src={cardProject3} alt="..." />
                </CardHeader>
              </a>
              <CardBody>
                <h6 className={classes.cardCategory}>ANDROID APP</h6>
                <a href="#pablo" onClick={e => e.preventDefault()}>
                  <h4 className={classes.cardTitle}>Analytics for Android</h4>
                </a>
                <p className={classes.cardDescription}>
                  Don{"'"}t be scared of the truth because we need to restart
                  the human foundation in truth.
                </p>
              </CardBody>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={5} md={5} className={classes.mrAuto}>
            <Card plain className={classes.card2}>
              <a href="#pablo" onClick={e => e.preventDefault()}>
                <CardHeader plain image>
                  <img src={cardProject4} alt="..." />
                </CardHeader>
              </a>
              <CardBody>
                <h6 className={classes.cardCategory}>WEBSITE</h6>
                <a href="#pablo" onClick={e => e.preventDefault()}>
                  <h4 className={classes.cardTitle}>Behance Redesign</h4>
                </a>
                <p className={classes.cardDescription}>
                  Don{"'"}t be scared of the truth because we need to restart
                  the human foundation in truth.
                </p>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}
