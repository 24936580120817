/*eslint-disable*/
import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
// @material-ui/icons
import Favorite from "@material-ui/icons/Favorite";
// core components
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Parallax from "components/Parallax/Parallax.js";
// sections for this page
import SectionPills from "./Sections/SectionPills.js";
// import SectionInterested from "./Sections/SectionInterested.js";
// import SectionImage from "./Sections/SectionImage.js";
// import SubscribeLine from "./Sections/SubscribeLine.js";

import propositionPageStyle from "assets/jss/material-kit-pro-react/views/propositionPageStyle.js";

const useStyles = makeStyles(propositionPageStyle);

export default function BlogPostsPage() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  });
  const classes = useStyles();
  return (
    <div className={classes.main}>
      <SectionPills />
      {/* <SectionInterested /> */}
      {/* <SectionImage /> */}
      {/* <SubscribeLine /> */}
    </div>

  );
}
