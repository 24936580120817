import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
// @material-ui/icons
import FormatAlignLeft from "@material-ui/icons/FormatAlignLeft";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import NavPills from "components/NavPills/NavPills.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";

import office2 from "assets/img/examples/office2.jpg";
import blog8 from "assets/img/examples/blog8.jpg";
import cardProject6 from "assets/img/examples/card-project6.jpg";

import sectionPillsStyle from "assets/jss/material-kit-pro-react/views/blogPostsSections/sectionPillsStyle.js";
import EcommercePage from "views/EcommercePage/EcommercePage.js";
import ProductPage from "views/ProductPage/ProductPage.js";
import SectionContacts from "views/SectionsPage/Sections/SectionContacts.js";
import LoginPage from "views/LoginPage/LoginPage.js";


//import SectionExamples from "views/PresentationPage/Sections/SectionExamples.js";
const useStyles = makeStyles(sectionPillsStyle);

export default function SectionPills() {
  const classes = useStyles();
  return (
    <div className={classes.section}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={12} className={classes.textCenter_}>
          <div className={classes.textCenter}>
          <Button href="/" simple color="primary" size="lg">
          inREALTIME
                    </Button>
                   
                  </div>
          <NavPills
            alignCenter
            tabs={[
              {
                tabButton: "Catalogue",
                tabContent: <EcommercePage />
              },
              {
                tabButton: "Products",
                tabContent: <ProductPage />
              },
              {
                tabButton: "Contact us",
                tabContent: <SectionContacts />
              },
              {
                tabButton: "Login",
                tabContent: <LoginPage />
              }
            ]}
          />
        </GridItem>
      </GridContainer>

    </div>
  );
}
