import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// react components for routing our app without refresh
import { Link } from "react-router-dom";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import examplesStyle from "assets/jss/material-kit-pro-react/views/presentationSections/examplesStyle.js";
import projectsStyle from "assets/jss/material-kit-pro-react/views/sectionsSections/projectsStyle.js";

import imagesStyles from "assets/jss/material-kit-pro-react/imagesStyles.js";
import Muted from "components/Typography/Muted.js";

import combo1 from "assets/img/images/79_441f5ce3_1_.jpg";
import combo2 from "assets/img/images/75_068e1f3d_1_.jpg";
import combo3 from "assets/img/images/86_e1eb88eb_1_.jpg";
import imageQRCODEinREALTIME from "assets/img/QRCODEinREALTIME.jpg";
import combo4 from "assets/img/domaineSearch.jpg";
import combo5 from "assets/img/reporting.jpg";

import imageReferencement from "assets/img/referencement.jpg";
import cardProject1 from "assets/img/examples/card-project1.jpg";
import cardProject2 from "assets/img/examples/card-project2.jpg";
import cardProject3 from "assets/img/inrealtimeAppleAndroid.jpg";
import cardProject4 from "assets/img/examples/card-project4.jpg";


// import exAboutUs from "assets/img/assets-for-demo/example-pages/ex-about-us.jpg";
// import exLanding from "assets/img/assets-for-demo/example-pages/ex-landing.jpg";
// import exContact from "assets/img/assets-for-demo/example-pages/ex-contact.jpg";
// import exBlogPost from "assets/img/assets-for-demo/example-pages/ex-blog-post.jpg";
// import exProductPage from "assets/img/assets-for-demo/example-pages/ex-product.jpg";
// import exLoginPage from "assets/img/assets-for-demo/example-pages/ex-login.jpg";
// import exPricing from "assets/img/assets-for-demo/example-pages/ex-pricing.jpg";
// import exSignup from "assets/img/assets-for-demo/example-pages/ex-register.jpg";
// import exProfile from "assets/img/assets-for-demo/example-pages/ex-profile.jpg";
// import exBlog from "assets/img/assets-for-demo/example-pages/ex-blog-posts.jpg";

const styles = {
  ...examplesStyle,
  ...imagesStyles,
  ...projectsStyle
};

const useStyles = makeStyles(styles);

export default function SectionExamples() {
  const classes = useStyles();
  return (
    <div className={classNames(classes.section, classes.sectionDark)}>
      <div className={classes.container}>
        <GridContainer>
          <GridItem
            xs={12}
            sm={8}
            md={8}
            className={
              classes.mlAuto + " " + classes.mrAuto + " " + classes.textCenter
            }
          >
            <h2 className={classes.title}>
              Details</h2>
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} sm={5} md={5} className={classes.mlAuto}>
            <Card plain className={classes.card2}>
              <Link to={"/proposition-page"}>
                <img
                  src={combo1}
                  alt="E-SHOP TEMPLATE"
                  className={classes.imgCard}
                />
              </Link>
              <CardBody>
                <h6 className={classes.cardCategory}>Click on the picture</h6>
                  <h4 className={classes.cardTitle}>
                    E-Shop Template                  </h4>
                <p className={classes.cardDescription}>
                Click on the image above to discover the e-shop template proposal,
                see presentation on previous pages.
                </p>
              </CardBody>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={5} md={5} className={classes.mrAuto}>
            <Card plain className={classes.card2}>
              <Link to="/hosting-page">
                <img
                  src={combo2}
                  alt="HOSTING"
                  className={classes.imgCard}
                />
              </Link>
              <CardBody>
                <h6 className={classes.cardCategory}>Click on the picture</h6>
                  <h4 className={classes.cardTitle}>
                    Hosting
                    </h4>
                <p className={classes.cardDescription}>
                 The physical hosting of your Website is provided by the OVH Company on a dedicated high availability server.
                </p>
              </CardBody>
            </Card>
          </GridItem>          
          <GridItem xs={12} sm={5} md={5} className={classes.mlAuto}>            
            <Card plain className={classes.card2}>
              <Link to={"/domain-page"}>
                <img
                  src={combo4}
                  alt="DOMAIN NAME"
                  className={classes.imgCard}
                />
              </Link>
              <CardBody>
                <h6 className={classes.cardCategory}>Click on the picture</h6>
                  <h4 className={classes.cardTitle}>
                    Domain Name
                  </h4>
                <p className={classes.cardDescription}>
                  If you want to buy your domain name to personalize your Website, use www.ovh.com/en/domains to check availability.
                </p>
              </CardBody>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={5} md={5} className={classes.mrAuto}>
          <Card plain className={classes.card2}>
              <Link to={"/support-page"}>
                <img
                  src={combo3}
                  alt="ONLINE SUPPORT"
                  className={classes.imgCard}
                />
              </Link>
              <CardBody>
                <h6 className={classes.cardCategory}>Click on the picture</h6>
                  <h4 className={classes.cardTitle}>
                    Online Support
                  </h4>
                <p className={classes.cardDescription}>
                  We offer 2 hours of assistance to get started with your Website, via WhatsApp.
                </p>
              </CardBody>
            </Card>
          </GridItem>          
          <GridItem xs={12} sm={5} md={5} className={classes.mlAuto}>
            <Card plain className={classes.card2}>
            <Link to={"/design-page"}>
                  <img 
                  src={cardProject1} alt="WEB DESIGN"
                  className={classes.imgCard} />
              </Link>
              <CardBody>
                <h6 className={classes.cardCategory}>Click on the picture</h6>
                  <h4 className={classes.cardTitle}>
                    Web Design
                  </h4>
                <p className={classes.cardDescription}>
                Discover our creations of e-shop websites and our best business websites
                </p>
              </CardBody>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={5} md={5} className={classes.mrAuto}>
            <Card plain className={classes.card2}>
                  <img src={combo5} alt="PRODUCTIVITY TOOLS"  className={classes.imgCard} />
              <CardBody>
                <h6 className={classes.cardCategory}>Click on the picture</h6>
                  <h4 className={classes.cardTitle}>
                    Productivity Tools
                  </h4>
                <p className={classes.cardDescription}>
                  Don{"'"}t be scared of the truth because we need to restart
                  the human foundation in truth.
                </p>
              </CardBody>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={5} md={5} className={classes.mlAuto}>
            <Card plain className={classes.card2}>
                  <img src={cardProject3} alt="IOS & ANDROID" className={classes.imgCard} />
              <CardBody>
              <h6 className={classes.cardCategory}>Click on the picture</h6>
                  <h4 className={classes.cardTitle}>APP for IOS and Android</h4>
                <p className={classes.cardDescription}>
                  Don{"'"}t be scared of the truth because we need to restart
                  the human foundation in truth.
                </p>
              </CardBody>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={5} md={5} className={classes.mrAuto}>
            <Card plain className={classes.card2}>
                <CardHeader plain image>
                  <img src={cardProject4} alt="SE0"  className={classes.imgCard} />
                </CardHeader>
              <CardBody>
                <h6 className={classes.cardCategory}>Click on the picture</h6>
                  <h4 className={classes.cardTitle}>SEO</h4>
                <p className={classes.cardDescription}>
                  Don{"'"}t be scared of the truth because we need to restart
                  the human foundation in truth.
                </p>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}
